import { FormikHelpers, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { StatCreate, Stat } from '../stat';
import { Grid, TextField, Paper, Box, Button } from '@mui/material';

type StatProps = {
  onSubmit: (
    value: StatCreate,
    helpers: FormikHelpers<StatCreate>
  ) => Promise<boolean>;
  submitting: boolean;
  stat: Stat | null;
};

const CreateStatForm = ({ onSubmit, submitting, stat }: StatProps) => {
  const navigate = useNavigate();

  const handleSubmit = async (
    value: StatCreate,
    helpers: FormikHelpers<StatCreate>
  ) => {
    const success = await onSubmit(value, helpers);
    if (success) {
      navigate('/', { replace: true });
      navigate('/stats', { replace: true });
    }
  };

  const initialValues: StatCreate =
    stat === null
      ? { farm: '', technique_units: '', harvest: '', export_amount: '' }
      : {
          farm: stat.farm,
          technique_units: stat.technique_units,
          harvest: stat.harvest,
          export_amount: stat.export_amount,
        };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    <>
      <Box sx={{ flexGrow: 1, maxWidth: 800 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item>
              <Paper sx={{ p: 2, pb: 3 }}>
                <Grid container justifyContent="space-between" spacing={1}>
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="farm"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Farm(in hectares)"
                      placeholder=""
                      value={formik.values.farm}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.farm)}
                      helperText={formik.errors.farm}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="technique_units"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Technique units"
                      placeholder=""
                      value={formik.values.technique_units}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.technique_units)}
                      helperText={formik.errors.technique_units}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="harvest"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Harvest (tonnes)"
                      placeholder=""
                      value={formik.values.harvest}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.harvest)}
                      helperText={formik.errors.harvest}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="export_amount"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Export Amount"
                      placeholder=""
                      value={formik.values.export_amount}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.export_amount)}
                      helperText={formik.errors.export_amount}
                    />
                  </Grid>
                </Grid>

                <Grid container sx={{ mt: 2 }}>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={submitting}
                    size="small"
                  >
                    {submitting ? 'Saving' : 'Save'}
                  </Button>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default CreateStatForm;
