import { FormikHelpers, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { PartnerCreate } from '../partner';
import {
  Grid,
  TextField,
  Box,
  FormHelperText,
  Paper,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { useState } from 'react';

type PartnerProps = {
  onSubmit: (
    value: PartnerCreate,
    helpers: FormikHelpers<PartnerCreate>
  ) => Promise<boolean>;
  submitting: boolean;
};

const CreatePartnerForm = ({ onSubmit, submitting }: PartnerProps) => {
  const [prot, setProt] = useState('https://');
  const navigate = useNavigate();

  const handleSubmit = async (
    value: PartnerCreate,
    helpers: FormikHelpers<PartnerCreate>
  ) => {
    const success = await onSubmit(value, helpers);
    if (success) {
      navigate('/', { replace: true });
      navigate('/partners/create', { replace: true });
    }
  };

  const initialValues: PartnerCreate = {
    name: '',
    image: null,
    category: '',
    link: '',
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    <>
      <Box sx={{ flexGrow: 1, maxWidth: 800 }}>
        <form>
          <Grid container spacing={1}>
            <Grid item>
              <Paper sx={{ p: 2, pb: 3 }}>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="name"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Name"
                      placeholder=""
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.name)}
                      helperText={formik.errors.name}
                    />
                    {Boolean(formik.errors.name) && (
                      <FormHelperText>{formik.errors.name}</FormHelperText>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <FormControl
                      sx={{ minWidth: 120 }}
                      fullWidth
                      error={Boolean(formik.errors.category)}
                    >
                      <InputLabel>Category</InputLabel>
                      <Select
                        value={formik.values.category}
                        size="small"
                        label="Category"
                        onChange={formik.handleChange}
                        name="category"
                      >
                        <MenuItem value="donor">Donor</MenuItem>
                        <MenuItem value="project">Project Partnership</MenuItem>
                      </Select>
                      {Boolean(formik.errors.category) && (
                        <FormHelperText>
                          {formik.errors.category}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                  display="flex"
                >
                  <Grid item alignSelf="flex-start" sx={{ mr: 1 }}>
                    <FormControl sx={{ minWidth: 60 }}>
                      <InputLabel>Category</InputLabel>
                      <Select
                        value={prot}
                        size="small"
                        label="Category"
                        onChange={(e) => {
                          setProt(e.target.value);
                        }}
                        name="category"
                      >
                        <MenuItem value="https://">https://</MenuItem>
                        <MenuItem value="http://">http://</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="link"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Link"
                      placeholder=""
                      value={formik.values.link}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.link)}
                      helperText={formik.errors.link}
                    />
                    {Boolean(formik.errors.link) && (
                      <FormHelperText>{formik.errors.link}</FormHelperText>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <Grid item xs={12}>
                      Image
                    </Grid>
                    <Box>
                      <input
                        type="file"
                        onChange={({ target: { files } }) => {
                          formik.setFieldValue(
                            'image',
                            files && files.length ? files[0] : null
                          );
                        }}
                        name="image"
                      />
                    </Box>
                    {formik.touched.image && formik.errors.image && (
                      <FormHelperText error>
                        {formik.errors.image}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>

                <Grid container sx={{ mt: 2 }}>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={submitting}
                    size="small"
                    onClick={() => {
                      formik.setFieldValue(
                        'link',
                        `${prot}${formik.values.link}`
                      );
                      formik.submitForm();
                    }}
                  >
                    {submitting ? 'Saving' : 'Save'}
                  </Button>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default CreatePartnerForm;
