import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PrivateRoute from '../../features/auth/containers/PrivateRoute';
import LoginPage from '../../features/auth/pages/LoginPage';
import ProfilePage from '../../features/auth/pages/ProfilePage';
import HomePage from '../../features/HomePage';
import ListContactPage from '../../features/contact/pages/ListContactPage';
import ListGalleryPage from '../../features/gallery/pages/ListGalleryPage';
import CreateGalleryPage from '../../features/gallery/pages/CreateGalleryPage';
import EditGalleryPage from '../../features/gallery/pages/EditGalleryPage';
import CreateStatPage from '../../features/stat/pages/CreateStatPage';
import ListNewsPage from '../../features/news/pages/ListNewsPage';
import CreateNewsPage from '../../features/news/pages/CreateNewsPage';
import EditNewsPage from '../../features/news/pages/EditNewsPage';
import ListPartnerPage from '../../features/partner/pages/ListPartnerPage';
import CreatePartnerPage from '../../features/partner/pages/CreatePartnerPage';
import EditPartnerPage from '../../features/partner/pages/EditPartnerPage';
import ListProjectPage from '../../features/project/pages/ListProjectPage';
import CreateProjectPage from '../../features/project/pages/CreateProjectPage';
import EditProjectPage from '../../features/project/pages/EditProjectPage';
import ListPublicationPage from '../../features/publication/pages/ListPublicationPage';
import CreatePublicationPage from '../../features/publication/pages/CreatePublicationPage';
import EditPublicationPage from '../../features/publication/pages/EditPublicationPage';
import ListTestimonyPage from '../../features/testimony/pages/ListTestimonyPage';
import CreateTestimonyPage from '../../features/testimony/pages/CreateTestimonyPage';
import EditTestimonyPage from '../../features/testimony/pages/EditTestimonyPage';
import ListTeamPage from '../../features/team/pages/ListTeamPage';
import CreateTeamPage from '../../features/team/pages/CreateTeamPage';
import EditTeamPage from '../../features/team/pages/EditTeamPage';

const RouteManager = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <HomePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <ProfilePage />
            </PrivateRoute>
          }
        />

        <Route
          path="/contacts"
          element={
            <PrivateRoute>
              <ListContactPage />
            </PrivateRoute>
          }
        />

        {/* Gallery  */}
        <Route
          path="/galleries"
          element={
            <PrivateRoute>
              <ListGalleryPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/galleries/create"
          element={
            <PrivateRoute>
              <CreateGalleryPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/galleries/edit/:id"
          element={
            <PrivateRoute>
              <EditGalleryPage />
            </PrivateRoute>
          }
        />

        {/* Stat  */}
        <Route
          path="/stats"
          element={
            <PrivateRoute>
              <CreateStatPage />
            </PrivateRoute>
          }
        />

        {/* News  */}
        <Route
          path="/news"
          element={
            <PrivateRoute>
              <ListNewsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/news/create"
          element={
            <PrivateRoute>
              <CreateNewsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/news/edit/:id"
          element={
            <PrivateRoute>
              <EditNewsPage />
            </PrivateRoute>
          }
        />

        {/* Partner  */}
        <Route
          path="/partners"
          element={
            <PrivateRoute>
              <ListPartnerPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/partners/create"
          element={
            <PrivateRoute>
              <CreatePartnerPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/partners/edit/:id"
          element={
            <PrivateRoute>
              <EditPartnerPage />
            </PrivateRoute>
          }
        />

        {/* Project  */}
        <Route
          path="/projects"
          element={
            <PrivateRoute>
              <ListProjectPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/projects/create"
          element={
            <PrivateRoute>
              <CreateProjectPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/projects/edit/:id"
          element={
            <PrivateRoute>
              <EditProjectPage />
            </PrivateRoute>
          }
        />
        {/* Publication  */}
        <Route
          path="/publications"
          element={
            <PrivateRoute>
              <ListPublicationPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/publications/create"
          element={
            <PrivateRoute>
              <CreatePublicationPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/publications/edit/:id"
          element={
            <PrivateRoute>
              <EditPublicationPage />
            </PrivateRoute>
          }
        />

        {/* Testimony  */}
        <Route
          path="/testimonies"
          element={
            <PrivateRoute>
              <ListTestimonyPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/testimonies/create"
          element={
            <PrivateRoute>
              <CreateTestimonyPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/testimonies/edit/:id"
          element={
            <PrivateRoute>
              <EditTestimonyPage />
            </PrivateRoute>
          }
        />

        {/* Team  */}
        <Route
          path="/teams"
          element={
            <PrivateRoute>
              <ListTeamPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/teams/create"
          element={
            <PrivateRoute>
              <CreateTeamPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/teams/edit/:id"
          element={
            <PrivateRoute>
              <EditTeamPage />
            </PrivateRoute>
          }
        />

        <Route path="*" element={<>Not Found page</>} />
      </Routes>
    </BrowserRouter>
  );
};

export default RouteManager;
