import { Grid, Typography } from "@mui/material";
import { FormikHelpers } from "formik";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Layout from "../../../core/ui/layout/Layout";
import { useSendApiData } from "../../../core/hooks/useSendApiData";
import { toastError, toastMessage } from "../../../core/utils/ui/alert";
import { parseValidationErrors } from "../../../core/utils/validation";
import useFetchApiData from "../../../core/hooks/useFetchApiData";
import Loader from "../../../core/ui/utility/Loader";
import { TeamEdit, Team } from "../team";
import EditTeamForm from "../components/EditTeamForm";
import ReturnButton from "../../../core/ui/utility/ReturnButton";
import { parseFormQuery } from "../../../core/utils/utility";

const EditTeamContainer = () => {
  const { id } = useParams();
  const { callApi, loading: submitting } = useSendApiData();
  const { fetchData, loading } = useFetchApiData();
  const [team, setTeam] = useState<Team | null>(null);

  const fetchTeam = () =>
    fetchData(`teams/${id}`, {
      onSuccess: (data: Team) => {
        setTeam(data);
      },
    });

  useEffect(() => {
    fetchTeam();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (
    values: TeamEdit,
    { setFieldError }: FormikHelpers<TeamEdit>
  ) => {
    const formData: any = parseFormQuery(values, [])

    await callApi({
      endpoint: `auth-ecff80/teams/${id}?_method=PATCH`,
      method: 'post',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        await fetchTeam();
        toastMessage('Team Edited');
      },
    });
  };

  return (
    <Layout renderLeftToolbar={() => <ReturnButton to="/teams" />}>
      <Loader loading={loading || !team}>
        <Grid sx={{ p: 2 }}>  
          <Grid container sx={{ mb: 1, px: 1 }}>
            <Typography variant="h5">Edit Team</Typography>
          </Grid>
          <EditTeamForm team={team!} onSubmit={handleSubmit} submitting={submitting} />
        </Grid>
      </Loader>
    </Layout>
  );
};

export default EditTeamContainer;
