
import { FormikHelpers, useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { TestimonyCreate } from "../testimony";
import { Grid, TextField, Box, FormHelperText, Paper, Button } from "@mui/material";


type TestimonyProps = {
  onSubmit: (
    value: TestimonyCreate,
    helpers: FormikHelpers<TestimonyCreate>
  ) => Promise<boolean>;
  submitting: boolean;
  
};

const CreateTestimonyForm = ({
  onSubmit,
  submitting,
  
}: TestimonyProps) => {
  const navigate = useNavigate();

  const handleSubmit = async (
    value: TestimonyCreate,
    helpers: FormikHelpers<TestimonyCreate>
  ) => {
    const success = await onSubmit(value, helpers);
    if (success) {
      navigate('/', { replace: true });
      navigate('/testimonies/create', { replace: true });
    };
  };

  const initialValues: TestimonyCreate =  {    name: '',
    position: '',
    testimony: '',
    image: null,
}

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    
<>
  <Box sx={{ flexGrow: 1, maxWidth: 800 }}>
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={1}>
      
<Grid item>
  <Paper sx={{ p: 2, pb: 3 }}>
  <Grid container justifyContent="space-between" spacing={1} sx={{ mt: 2 }}>
<Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
  <TextField
    fullWidth
    name="name"
    size="small"
    type="text"
    variant="outlined"
    label="Name"
    placeholder=""
    value={formik.values.name}
    onChange={formik.handleChange}
    error={Boolean(formik.errors.name)}
    helperText={formik.errors.name}
  />
</Grid>

<Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
  <TextField
    fullWidth
    name="position"
    size="small"
    type="text"
    variant="outlined"
    label="Position"
    placeholder=""
    value={formik.values.position}
    onChange={formik.handleChange}
    error={Boolean(formik.errors.position)}
    helperText={formik.errors.position}
  />
</Grid>
</Grid><Grid container justifyContent="space-between" spacing={1} sx={{ mt: 2 }}>
<Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
  <TextField
    fullWidth
    name="testimony"
    size="small"
    type="text"
    variant="outlined"
    label="Testimony"
    placeholder=""
    value={formik.values.testimony}
    onChange={formik.handleChange}
    error={Boolean(formik.errors.testimony)}
    helperText={formik.errors.testimony}
    multiline
    rows={4}
  />
</Grid>
</Grid><Grid container justifyContent="space-between" spacing={1} sx={{ mt: 2 }}>
<Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
  <Grid item xs={12}>
    User Image
  </Grid>
  <Box>
    <input
      type="file"
      onChange={({ target: { files } }) => {
        formik.setFieldValue(
          'image',
          files && files.length ? files[0] : null
        );
      }}
      name="image"
    />
  </Box>
  {formik.touched.image && formik.errors.image && (
    <FormHelperText error>
      {formik.errors.image}
    </FormHelperText>
  )}
</Grid>
</Grid>

    <Grid container sx={{ mt: 2 }}>
      <Button
        type="submit"
        variant="contained"
        disabled={submitting}
        size="small"
      >
        {submitting ? 'Saving' : 'Save'}
      </Button>
    </Grid>
  </Paper>
</Grid>

      </Grid>
    </form>
  </Box>
</>
  
  );
};

export default CreateTestimonyForm;
