import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import useFetchApiData from '../../../core/hooks/useFetchApiData';
import Layout from '../../../core/ui/layout/Layout';
import TableContainer from '../../../core/ui/table/TableContainer';
import { TableHeader } from '../../../core/ui/table/tableTypes';
import { Media, Paginated } from '../../../core/utils/types';
import { parseQuery } from '../../../core/utils/utility';
import { Team } from '../team';
import { useSendApiData } from '../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../core/utils/ui/alert';
import ImagePreview from '../../../core/ui/utility/ImagePreview';

const getPosition = (position: string) => {
  if (position === 'board') return 'Board Member';
  if (position === 'management') return 'Management Team';
  if (position === 'senior_site') return 'Senior Officers & Site-coordinators';

  return '';
};

const tableHeaders: TableHeader[] = [
  { field: 'name', label: 'Name', align: 'left' },
  { field: 'position', label: 'Position', align: 'left' },
  { field: 'description', label: 'Description', align: 'left' },
  {
    field: 'category',
    label: 'Category',
    align: 'left',
    renderItem: (val: string) => <Typography>{getPosition(val)}</Typography>,
  },
  { field: 'email', label: 'Email', align: 'left' },
  { field: 'linkedin', label: 'Linkedin', align: 'left' },
  {
    field: 'media',
    label: 'Image',
    align: 'left',
    renderItem: (item: Media[]) => <ImagePreview media={item} />,
  },
];
const TeamList = () => {
  const [data, setData] = useState<Record<string, any>[]>([]);
  const { fetchData } = useFetchApiData();
  const { callApi } = useSendApiData();

  const handleFetchData = async (query: any, cb: (c: number) => void) => {
    await fetchData(`teams${parseQuery(query)}`, {
      onSuccess: ({ total, data: apiData }: Paginated<Team>) => {
        setData(apiData);
        cb(total);
      },
    });
  };

  const handleDelete = async (id: number | string, showMessage = true) => {
    let success = false;

    await callApi({
      endpoint: `auth-ecff80/teams/${id}`,
      data: {},
      method: 'delete',
      onSuccess: async () => {
        if (showMessage) toastMessage('Item Removed');
        success = true;
      },
      onError: () => {
        if (showMessage)
          toastError('Something went wrong while deleting. Try again');
      },
    });

    return success;
  };

  return (
    <>
      <Layout>
        <Box sx={{ p: 2, maxWidth: 1400 }}>
          <TableContainer
            modelToken="teams"
            tableHeaders={tableHeaders}
            data={data}
            modelLabel="Teams"
            actions={{
              onFetchData: handleFetchData,
              onDelete: handleDelete,
            }}
            routes={{
              edit: '/teams/edit',
              create: '/teams/create',
              delete: '/teams',
              view: '/teams',
            }}
            settings={{
              canCreate: true,
              canEdit: true,
              canDelete: true,
              canViewItem: false,
            }}
          />
        </Box>
      </Layout>
    </>
  );
};

export default TeamList;
