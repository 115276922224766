
import { FormikHelpers, useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { GalleryCreate } from "../gallery";
import { Grid, TextField, Box, FormHelperText, Paper, Button } from "@mui/material";


type GalleryProps = {
  onSubmit: (
    value: GalleryCreate,
    helpers: FormikHelpers<GalleryCreate>
  ) => Promise<boolean>;
  submitting: boolean;
  
};

const CreateGalleryForm = ({
  onSubmit,
  submitting,
  
}: GalleryProps) => {
  const navigate = useNavigate();

  const handleSubmit = async (
    value: GalleryCreate,
    helpers: FormikHelpers<GalleryCreate>
  ) => {
    const success = await onSubmit(value, helpers);
    if (success) {
      navigate('/', { replace: true });
      navigate('/galleries/create', { replace: true });
    };
  };

  const initialValues: GalleryCreate =  {    alt: '',
    image: null,
}

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    
<>
  <Box sx={{ flexGrow: 1, maxWidth: 800 }}>
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={1}>
      
<Grid item>
  <Paper sx={{ p: 2, pb: 3 }}>
  <Grid container justifyContent="space-between" spacing={1} sx={{ mt: 2 }}>
<Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
  <TextField
    fullWidth
    name="alt"
    size="small"
    type="text"
    variant="outlined"
    label="Description"
    placeholder=""
    value={formik.values.alt}
    onChange={formik.handleChange}
    error={Boolean(formik.errors.alt)}
    helperText={formik.errors.alt}
  />
</Grid>
</Grid><Grid container justifyContent="space-between" spacing={1} sx={{ mt: 2 }}>
<Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
  <Grid item xs={12}>
    Image
  </Grid>
  <Box>
    <input
      type="file"
      onChange={({ target: { files } }) => {
        formik.setFieldValue(
          'image',
          files && files.length ? files[0] : null
        );
      }}
      name="image"
    />
  </Box>
  {formik.touched.image && formik.errors.image && (
    <FormHelperText error>
      {formik.errors.image}
    </FormHelperText>
  )}
</Grid>
</Grid>

    <Grid container sx={{ mt: 2 }}>
      <Button
        type="submit"
        variant="contained"
        disabled={submitting}
        size="small"
      >
        {submitting ? 'Saving' : 'Save'}
      </Button>
    </Grid>
  </Paper>
</Grid>

      </Grid>
    </form>
  </Box>
</>
  
  );
};

export default CreateGalleryForm;
