import { FormikHelpers, useFormik } from 'formik';
import { ProjectEdit, Project } from '../project';
import {
  Grid,
  TextField,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Paper,
  Button,
} from '@mui/material';
import { getMediaUrl } from '../../../core/utils/image';
import RichText from '../../../core/ui/input/RichText';

type ProjectProps = {
  onSubmit: (
    value: ProjectEdit,
    helpers: FormikHelpers<ProjectEdit>
  ) => Promise<void>;
  submitting: boolean;
  project: Project;
};

const EditProjectForm = ({ onSubmit, submitting, project }: ProjectProps) => {
  const handleSubmit = async (
    value: ProjectEdit,
    helpers: FormikHelpers<ProjectEdit>
  ) => {
    await onSubmit(value, helpers);
  };

  const initialValues: ProjectEdit = {
    title: project.title,
    description: project.description,
    content: project.content,
    status: project.status,
    from: project.from,
    end: project.end,
    image: null,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    <>
      <Box sx={{ flexGrow: 1, maxWidth: 800 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item>
              <Paper sx={{ p: 2, pb: 3 }}>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="title"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Title"
                      placeholder=""
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.title)}
                      helperText={formik.errors.title}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="description"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Description"
                      placeholder=""
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.description)}
                      helperText={formik.errors.description}
                      multiline
                      rows={4}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <Grid item xs={12}>
                      Content
                    </Grid>
                    <RichText
                      value={formik.values.content || ''}
                      onChange={(e) => {
                        formik.setFieldValue('content', e);
                      }}
                    />
                    {formik.touched.content && formik.errors.content && (
                      <FormHelperText error>
                        {formik.errors.content}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <FormControl
                      sx={{ minWidth: 120 }}
                      fullWidth
                      error={Boolean(formik.errors.status)}
                    >
                      <InputLabel>Status</InputLabel>
                      <Select
                        value={formik.values.status}
                        size="small"
                        label="Status"
                        onChange={formik.handleChange}
                        name="status"
                      >
                        <MenuItem value="inprogress">In Progress</MenuItem>
                        <MenuItem value="completed">Completed</MenuItem>
                      </Select>
                      {Boolean(formik.errors.status) && (
                        <FormHelperText>{formik.errors.status}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="from"
                      size="small"
                      type="number"
                      variant="outlined"
                      label="From(year)"
                      placeholder=""
                      value={formik.values.from}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.from)}
                      helperText={formik.errors.from}
                    />
                  </Grid>

                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="end"
                      size="small"
                      type="number"
                      variant="outlined"
                      label="End(year)"
                      placeholder=""
                      value={formik.values.end}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.end)}
                      helperText={formik.errors.end}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <Grid item xs={12}>
                      Cover Image
                    </Grid>
                    <Grid item sx={{ mr: 3 }}>
                      <img
                        src={getMediaUrl(
                          project.media.length ? project.media[0] : null
                        )}
                        width="256px"
                        alt="cover"
                      />
                    </Grid>
                    <Box>
                      <input
                        type="file"
                        onChange={({ target: { files } }) => {
                          formik.setFieldValue(
                            'image',
                            files && files.length ? files[0] : null
                          );
                        }}
                        name="image"
                      />
                    </Box>
                    {formik.touched.image && formik.errors.image && (
                      <FormHelperText error>
                        {formik.errors.image}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>

                <Grid container sx={{ mt: 2 }}>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={submitting}
                    size="small"
                  >
                    {submitting ? 'Saving' : 'Save'}
                  </Button>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default EditProjectForm;
