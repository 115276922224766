import { Grid, Typography } from '@mui/material';
import { FormikHelpers } from 'formik';
import Layout from '../../../core/ui/layout/Layout';
import { useSendApiData } from '../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../core/utils/validation';
import { StatCreate, Stat } from '../stat';
import CreateStatForm from '../components/CreateStatForm';
import ReturnButton from '../../../core/ui/utility/ReturnButton';
import { parseFormQuery } from '../../../core/utils/utility';
import { useState, useEffect } from 'react';
import useFetchApiData from '../../../core/hooks/useFetchApiData';
import Loader from '../../../core/ui/utility/Loader';

const CreateStatContainer = () => {
  const { callApi, loading: submitting } = useSendApiData();
  const { fetchData, loading } = useFetchApiData();
  const [stat, setStat] = useState<Stat | null>(null);

  const fetchStat = () =>
    fetchData(`stats`, {
      onSuccess: (data: Stat) => {
        setStat(data);
      },
    });

  useEffect(() => {
    fetchStat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (
    values: StatCreate,
    { setFieldError }: FormikHelpers<StatCreate>
  ) => {
    let success = false;

    const formData: any = parseFormQuery(values, []);

    await callApi({
      endpoint: 'auth-ecff80/stats',
      data: formData,
      headers: {
        'Content-Type': 'application/json',
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        toastMessage('Stat Created');
        success = true;
      },
    });

    return success;
  };

  return (
    <Layout renderLeftToolbar={() => <ReturnButton to="/stats" />}>
      <Loader loading={loading}>
        <Grid sx={{ p: 2 }}>
          <Grid container sx={{ mb: 2, px: 1 }}>
            <Typography variant="h5">{stat ? 'Edit' : 'Add'} Stat</Typography>
          </Grid>
          <CreateStatForm
            stat={stat}
            onSubmit={handleSubmit}
            submitting={submitting}
          />
        </Grid>
      </Loader>
    </Layout>
  );
};

export default CreateStatContainer;
