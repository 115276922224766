import Layout from '../core/ui/layout/Layout';

const HomePage = () => {
  return (
    <Layout>
      <> </>
    </Layout>
  );
};

export default HomePage;
